import React, { useEffect, useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import {
  Market,
  SearchInput,
  useAuth,
  useLocation,
  getStoredMarket,
  getStoredDistributionCenter,
  DistributionCenter,
  getShippingCosts,
} from '@ecommerce/shared'
import styled from 'styled-components'
import { RibbonMessageSliderWidget } from '../../components/RibbonMessage'
import { Icon } from '../../components/Icon/Icon'
import { TabMenuMobile } from '../../components/TabMenu'
import { SearchBarContainer, SectionContainer } from './styled'
import homeBlocksQuery, { HomeBlocksQueryResult } from '../../graphql/homeBlocksQuery'
import { Block, BlockType } from '../../graphql/contentfulTypes'
import { useCartStockErrorHandler } from '../../utils/errors'
import { getTabs } from '../../mocks'
import { CategoriesSkeleton } from './Categories'
import useQuery from '../../hooks/useQuery'
import { BannerSliderWidget } from '../../components/BannerSlider'
import FavoriteProducts from './FavoriteProducts'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import { extractBlocksSkus, extractFilters, generateQueries } from '../utils'
import Promotions from './Promotions'
import MomentsCardsListWidget from './MomentsCardsList'
import SkuList from './SkuList'
import Steps from '../../components/Steps'
import { BannerFluidList } from '../../components/BannerFluid'
import Hero from '../../components/Moments/Hero'
import ProductVideo from '../../components/ProductDetail/ProductVideo'
import ProductSlidesList from '../../components/Moments/ProductSlidesList'
import FilteredProductList from '../../components/Moments/FilteredProductList'
import RecipeList from '../../components/Recipes/RecipesList'
import BrandsList from '../../components/Moments/BrandsList'
import VideosList from '../../components/Moments/VideosList'
import NewsList from '../../components/News/NewsList'
import HowItWorks from '../../components/Moments/HowItWorks'
import BrandsSliderWidget from '../../components/BrandsSlider/BrandsSliderWidget'
import { sendSelectItemEvent } from '../../utils/events'
import { generateProductUrl } from '../../config/siteBuild/utils'
import useMultipleQueries from './useMultipleQueries'

const Categories = loadable(() => import('./Categories'), {
  fallback: <CategoriesSkeleton />,
})
const PromosGridWidget = loadable(() => import('../../components/Moments/PromosGrid/PromosGridWidget'))
const HeadingWidget = loadable(() => import('../../components/Heading/HeadingWidget'))
const CountdownWidget = loadable(() => import('../../components/Countdown/CountdownWidget'))

type GQLNodeBlocks = Omit<Block, 'title' | 'items' | 'sys'>[]

const HomeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.body};
`

interface Props {
  currentMarket: Market
  currentDistributionCenter: DistributionCenter
  pageBlocks: GQLNodeBlocks
  tabs: ReturnType<typeof getTabs>
}

const Home = ({ tabs, currentDistributionCenter, currentMarket }: Props) => {
  const { isBolivia } = useLocation()
  const storedMarket = getStoredMarket() || currentMarket
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const [blocks, setBlocks] = useState<GQLNodeBlocks>([])

  const {
    state: { isAuth },
  } = useAuth()

  const { data: blocksData } = useQuery<HomeBlocksQueryResult>(homeBlocksQuery, {
    variables: { currentCitySlug: storedMarket?.name },
    deps: [isAuth],
  })

  const blocksCollection = blocksData?.pgPageCollection?.items?.[0]?.blocksCollection?.items?.length
    ? blocksData?.pgPageCollection?.items?.[0]?.blocksCollection?.items
    : blocksData?.pgPageCollection?.items?.[0]?.template?.blocksCollection?.items

  const skus = useMemo(() => extractBlocksSkus(blocksCollection ?? []), [blocksCollection])

  const filters = useMemo(() => extractFilters(blocksCollection ?? []), [blocksCollection])

  const queries = useMemo(() => generateQueries(filters, storedDistributionCenter.slug, true), [filters])

  const [results, loading] = useMultipleQueries(queries)

  const { products, loadingProducts } = useAlgoliaFetch({
    distributionCenter: storedDistributionCenter,
    skus,
  })

  function formatBlocksClientSide() {
    if (blocksData) {
      const blockItems = blocksData.pgPageCollection.items[0].blocksCollection.items.length
        ? blocksData.pgPageCollection.items[0].blocksCollection.items
        : blocksData.pgPageCollection.items[0].template.blocksCollection.items

      const newBlocks = blockItems.map((item) => ({
        blockType: item.blockType,
        contentful_id: item.sys.id,
        name: item.name,
        background: item.background,
      }))

      setBlocks(newBlocks)
    }
  }

  useEffect(formatBlocksClientSide, [blocksData])

  useEffect(() => {
    getShippingCosts()
  }, [])

  const backupSlider = !blocks.some((block) => block.blockType === BlockType.BANNER_SLIDER) ? (
    <>
      <SearchBarContainer>
        <SearchInput
          className="search-bar"
          type="text"
          placeholder="Busca algún producto"
          data-test="search-input"
          onClick={() => navigate(`/search`)}
          CustomSearchIcon={() => <Icon iconId="search" onClick={() => navigate(`/search`)} cursor="pointer" />}
        />
      </SearchBarContainer>
      <TabMenuMobile tabs={tabs} currentCity={currentMarket} />
    </>
  ) : null

  const stockErrorHandler = useCartStockErrorHandler()

  return (
    <HomeWrapper>
      {backupSlider}
      {blocks?.map((block, index) => {
        const { contentful_id, name } = block
        const hasSearchboxPadding = index > 0 && blocks[index - 1].blockType === BlockType.BANNER_SLIDER
        const background = block.background?.url

        switch (block.blockType) {
          case BlockType.PROMO_LARGE:
          case BlockType.PROMO_SMALL: {
            return (
              <SectionContainer
                hasSearchboxPadding={hasSearchboxPadding}
                key={name}
                className="promos-container"
                background={background}
                hasAltBackground
              >
                <Promotions showRibbon={false} listId={contentful_id} />
              </SectionContainer>
            )
          }

          case BlockType.HEADING: {
            return <HeadingWidget listId={contentful_id} key={name} />
          }

          case BlockType.COUNTDOWN: {
            return <CountdownWidget listId={contentful_id} key={name} />
          }

          case BlockType.CATEGORY: {
            return (
              <SectionContainer hasSearchboxPadding={hasSearchboxPadding} key={name}>
                <Categories listId={contentful_id} />
              </SectionContainer>
            )
          }

          case BlockType.PRODUCT: {
            return (
              <SkuList
                loadingProducts={loadingProducts}
                pageProducts={products}
                key={name}
                listId={contentful_id}
                stockErrorHandler={stockErrorHandler}
                useBackground
                type="large"
              />
            )
          }

          case BlockType.BANNER_SLIDER: {
            return (
              <React.Fragment key={name}>
                <BannerSliderWidget listId={contentful_id} />
              </React.Fragment>
            )
          }

          case BlockType.PROMO_XL_GRID: {
            return <PromosGridWidget key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.MOMENT_CARD: {
            return <MomentsCardsListWidget key={block.contentful_id} listId={contentful_id} />
          }

          case BlockType.FAVORITES: {
            return isAuth ? <FavoriteProducts key={name} listId={block.contentful_id} /> : null
          }

          case BlockType.RIBBON_MESSAGES: {
            return (
              <React.Fragment>
                <RibbonMessageSliderWidget
                  key={block.contentful_id}
                  marketSlug={currentMarket.name}
                  listId={block.contentful_id}
                />
              </React.Fragment>
            )
          }

          case BlockType.TEXT_WITH_ICON: {
            return (
              <div key={contentful_id}>
                <Steps listId={contentful_id} />
              </div>
            )
          }

          case BlockType.BANNER_FLUID: {
            return <BannerFluidList listId={block.contentful_id} key={block.contentful_id} />
          }

          case BlockType.HERO: {
            return <Hero key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.SINGLE_VIDEO: {
            return <ProductVideo altBackground key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.PRODUCT_SLIDE: {
            return (
              <ProductSlidesList
                key={block.contentful_id}
                listId={block.contentful_id}
                currentDistributionCenter={currentDistributionCenter}
              />
            )
          }

          case BlockType.FILTERED_LIST: {
            return (
              <FilteredProductList
                isHome
                key={block.contentful_id}
                typeCard="large"
                background={background}
                listId={block.contentful_id}
                items={
                  block.blockType === 'filtered-list'
                    ? results?.find((result) => result.queryID === block.contentful_id)?.hits
                    : undefined
                }
                onCardClick={({ product, listName, themeId }) => {
                  sendSelectItemEvent(product, listName, isBolivia())
                  navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
                    state: { themeId, originalListName: listName },
                  })
                }}
              />
            )
          }

          case BlockType.RECIPE: {
            return (
              <RecipeList
                key={block.contentful_id}
                listId={block.contentful_id}
                actionSlug={block.actionSlug ? `/momento/${block.actionSlug}` : undefined}
              />
            )
          }

          case BlockType.BRANDS_LIST: {
            return <BrandsList key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.VIDEO: {
            return <VideosList key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.NEWS: {
            return <NewsList key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.HOW_IT_WORKS: {
            return <HowItWorks key={block.contentful_id} listId={block.contentful_id} />
          }

          case BlockType.BRAND: {
            return <BrandsSliderWidget key={block.contentful_id} listId={block.contentful_id} />
          }

          default:
            return null
        }
      })}
    </HomeWrapper>
  )
}

export default Home
