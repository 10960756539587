import { OrListFragment, MlHeroFragment } from './fragments'
import { BlockType, ClientSideMlHero } from './contentfulTypes'

export interface QueryHeroResult {
  orList: {
    blockType: BlockType.HERO
    itemsCollection: {
      items: ClientSideMlHero[]
    }
  }
}

export default `
  query HeroQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
        itemsCollection {
          items {
          ...MlHeroFragment
        }
      }
   }
  }
  ${OrListFragment}
  ${MlHeroFragment}
`
