import React from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, Image, Button, breakpoints } from '@ecommerce/shared'
import { ClientSideMlHero, Hero } from '../../../graphql/contentfulTypes'

type Props = {
  data: ClientSideMlHero
}

type CTAProps = {
  images: {
    mobile: string
    tablet: string
    desktop: string
  }
  hasDescription: boolean
  addPaddings: boolean
}

type WrapperProps = {
  hasDescription: boolean
  addPaddings: boolean
  backgroundColor?: string
  textColor?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('HeroWithCTA__')

const Wrapper = styled.div<WrapperProps>`
  padding: ${({ addPaddings }) => (addPaddings ? '30px 10px 0' : '48px 21px 0 24px')};

  .${cssPrefix} {
    &inner {
      ${({ addPaddings }) => addPaddings && 'padding: 25px;'}
      border-radius: 40px;
      background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.brand.default};
      display: flex;
      flex-direction: column-reverse;
      max-width: 1400px;
      margin: auto;
    }

    &text {
      color: ${({ textColor, theme }) => textColor || theme.colors.brandText.default};
      margin: 18px 0;
      text-align: center;
    }

    &button {
      min-width: 240px;
      font-size: 15px;
      margin: auto;
      display: block;
      height: 50px;
      white-space: nowrap;
      padding: 0 20px;
    }

    ${({ addPaddings, hasDescription }) =>
      !addPaddings &&
      css`
        &left {
          padding: ${hasDescription ? '15px 17px 17px 17px' : '49px 17px 52px 17px'};
        }
      `}

    &right {
      ${({ addPaddings }) => !addPaddings && 'max-height: 220px;'}

      &-image {
        width: 100%;
        max-height: ${({ addPaddings }) => (addPaddings ? '260px' : '220px')};
        object-fit: ${({ addPaddings }) => (addPaddings ? 'contain' : 'cover')};
        user-select: none;
        user-drag: none;
        ${({ addPaddings }) => !addPaddings && 'border-radius: 40px 40px 0 0;'}
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding: ${({ addPaddings }) => (addPaddings ? '35px 25px 0' : '48px 29px 0 30px;')};

    .${cssPrefix} {
      ${({ addPaddings }) =>
        addPaddings
          ? css`
              &inner {
                flex-direction: row;
                align-items: center;
                padding: 45px 0 20px 25px;
              }

              &left,
              &right {
                width: 50%;
              }

              &right {
                &-image {
                  max-height: 340px;
                  margin-left: 5px;
                }
              }

              &text {
                margin: 30px 0;
                text-align: left;
              }

              &button {
                margin: 0;
                font-size: 18px;
              }
            `
          : css`
              &left {
                padding: 40px 112px 71px 109px;
              }

              &right {
                max-height: 250px;

                &-image {
                  max-height: 250px;
                }
              }

              &text {
                font-size: 18px;
              }
            `}
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 56px 65px 0;

    .${cssPrefix} {
      &inner {
        padding: ${({ addPaddings }) => (addPaddings ? '20px 0 20px 70px' : '0')};
        flex-direction: row;
        align-items: center;
        ${({ addPaddings }) => !addPaddings && 'height: 464px;'}
      }

      &text {
        margin: ${({ addPaddings }) => (addPaddings ? '20px 0' : '30px 0')};
        font-size: 24px;
        max-width: 400px;
        text-align: left;
        padding-right: 20px;
      }

      &button {
        width: 330px;
        height: 72px;
      }

      &right {
        ${({ addPaddings }) =>
          !addPaddings &&
          css`
            width: 50%;
            max-height: 100%;
            min-height: 464px;
            height: 464px;
            display: flex;
          `}

        &-image {
          max-height: ${({ addPaddings }) => (addPaddings ? '420px' : '100%')};
          margin-left: 0;

          ${({ addPaddings }) =>
            !addPaddings &&
            css`
              height: 100%;
              border-radius: 0 40px 40px 0;
            `}
        }
      }

      ${({ addPaddings, hasDescription }) =>
        !addPaddings &&
        css`
          &left {
            width: 50%;
            padding: ${hasDescription ? '20px 0px 20px 70px' : '116px 97px 116px 77px'};
          }

          &button {
            margin: 0;
            font-size: 18px;
          }
        `}
    }
  }
`

const CallToAction = styled.div<CTAProps>`
  width: 100%;
  height: ${({ hasDescription }) => (hasDescription ? '130px' : '160px')};
  background-image: url(${({ images }) => images.mobile});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    background-image: url(${({ images }) => images.tablet});
    height: ${({ addPaddings }) => (addPaddings ? '150px' : '120px')};
    background-position: ${({ addPaddings }) => (addPaddings ? 'left' : 'center')};
  }

  @media screen and (${breakpoints.desktop.min}) {
    background-image: url(${({ images }) => images.desktop});
    height: ${({ hasDescription }) => (hasDescription ? '200px' : '232px')};

    ${({ addPaddings, hasDescription }) =>
      !addPaddings &&
      css`
        background-position: ${hasDescription ? 'left' : 'center'};
      `}
  }
`

const HeroWithCTA = ({ data }: Props) => {
  const {
    image,
    text,
    textColor,
    buttonText,
    buttonSlug,
    callToActionImageDesktop,
    callToActionImageMobile,
    callToActionImageTablet,
    backgroundColor,
    heroType,
  } = data

  return (
    <Wrapper
      className={cssPrefix}
      hasDescription={!!text}
      backgroundColor={backgroundColor}
      textColor={textColor}
      addPaddings={heroType !== 'with-cta-no-padding'}
    >
      <div className={toPrefix('inner')}>
        <div className={toPrefix('left')}>
          <CallToAction
            images={{
              desktop: callToActionImageDesktop.url,
              tablet: callToActionImageTablet.url,
              mobile: callToActionImageMobile.url,
            }}
            hasDescription={!!text}
            addPaddings={heroType !== 'with-cta-no-padding'}
          />
          {text && <p className={toPrefix('text')}>{text}</p>}
          {buttonText && buttonSlug && (
            <Button onClick={() => navigate(`/${buttonSlug}`)} className={toPrefix('button')} btnColor="blue">
              {buttonText}
            </Button>
          )}
        </div>
        <div className={toPrefix('right')}>
          <Image className={toPrefix('right-image')} src={image.url} alt="call-to-action" />
        </div>
      </div>
    </Wrapper>
  )
}

export default HeroWithCTA
