import { RichTextJson } from './contentfulTypes'

export type QueryRibbonMessages = {
  message: RichTextJson
  url: string
}

export interface RibbonMessagesQueryResult {
  orList: {
    title: string
    blockType: 'ribbon-messages'
    itemsCollection: {
      items: QueryRibbonMessages[]
    }
  }
}

export default `
  query RibbonMessagesQuery($listId: String!) {
    orList(id: $listId) {
      title
      itemsCollection {
        items {
          ... on AtRibbonMessage {
            message {
              json
            }
            url
          }
        }
      }
    }
  }
`
