import React from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { breakpoints } from '@ecommerce/shared'

interface City {
  name?: string
  slug?: string
}

export interface Tab {
  label?: string
  icon?: React.ReactNode
  slug?: string
  dataTest: string
}

interface CommonProps {
  showMenu?: boolean
  pathName?: string
  currentCity?: City
  tabs?: Tab[]
  className?: string
}

const TabsMobile = loadable(() => import('./TabsMobile'))
const TabsDesktop = loadable(() => import('./TabsDesktop'))

const WrapperTabMenuDesktop = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  .compact-menu {
    background-color: ${({ theme }) => theme.colors.brand.default};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .compact-menu-tab-group {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 420px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    display: flex;
  }
`
const WrapperTabMenuMobile = styled.div`
  width: 100%;

  .compact-menu {
    background-color: ${({ theme }) => theme.colors.grey};
    height: 90px;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .compact-menu-tab-group {
    width: 80%;
    display: flex;
    justify-content: space-between;
    max-width: 320px;
  }
  @media (${breakpoints.desktop.min}) {
    display: none;

    .compact-menu {
      visibility: hidden;
    }
  }
`

export const TabMenuDesktop = React.memo(({ pathName, tabs }: Pick<CommonProps, 'pathName' | 'tabs'>) => (
  <WrapperTabMenuDesktop>
    <div className="compact-menu">
      <div className="compact-menu-tab-group">
        <TabsDesktop tabs={tabs} slug={pathName} />
      </div>
    </div>
  </WrapperTabMenuDesktop>
))

export const TabMenuMobile = React.memo((props: CommonProps) => {
  const { showMenu = true, pathName, tabs, currentCity, className } = props

  if (!showMenu) return null

  return (
    <WrapperTabMenuMobile className={className}>
      <div className="compact-menu">
        <div className="compact-menu-tab-group">
          <TabsMobile tabs={tabs} slug={`/${pathName ? `/${pathName}` : ''}`} />
        </div>
      </div>
    </WrapperTabMenuMobile>
  )
})
