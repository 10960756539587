const FiltersHomeSearch = [
  {
    label: 'Categorías',
    filter: 'mainCategoryName',
  },
  {
    label: 'Marcas',
    filter: 'brandName',
  },
  {
    label: 'Nivel de azúcar',
    filter: 'sugar',
  },
  {
    label: 'Retornabilidad',
    filter: 'returnability',
  },
  {
    label: 'Tamaño',
    filter: 'size',
  },
  {
    label: 'Sabor',
    filter: 'flavorName',
  },
]

export default FiltersHomeSearch
