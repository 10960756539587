import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Product, CartStockErrorHandler, ProductCardType, getCurrencyCode, toCurrencyNumber } from '@ecommerce/shared'
import productListQuery, { QueryResult } from '../../graphql/productListQuery'
import { ProductListSlider } from '../../components/ProductListSlider'
import SliderWrapper, { StyleProps } from '../../components/SliderWrapper'
import useQuery from '../../hooks/useQuery'
import { BlockPadding, BlockTitle } from '../../graphql/contentfulTypes'
import secrets from '../../config/secrets'
import { sendViewItemListEvent } from '../../utils/events'

const { COUNTRY } = secrets

type HomeListProps = {
  listId: string
  pageProducts: Product[]
  loadingProducts?: boolean
  type?: ProductCardType
  stockErrorHandler: CartStockErrorHandler
  styleProps?: StyleProps
  className?: string
  themeId?: string
  useBackground?: boolean
}

type HomeListData = {
  blockName?: string
  onShowMoreClick?: () => void
  skus: string[]
  background?: string
} & BlockPadding &
  BlockTitle

export default ({
  type,
  pageProducts,
  loadingProducts = true,
  stockErrorHandler,
  listId,
  styleProps = {},
  className = '',
  themeId,
  useBackground,
}: HomeListProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [renderList, setRenderList] = useState(true)
  const [data, setData] = useState<HomeListData>({
    title: '',
    centerTitle: false,
    allowTitleLowerCase: false,
    paddingTop: false,
    paddingBottom: false,
    skus: [],
  })
  const [products, setProducts] = useState<Product[]>([])

  const { data: queryResult, loading } = useQuery<QueryResult>(productListQuery, { variables: { listId } })

  useEffect(() => {
    if (queryResult && !loading) {
      const handleResult = () => {
        const block = queryResult?.orList
        const skus = block?.itemsCollection.items.map(({ sku }) => sku)

        if (!skus || skus.length === 0) return setRenderList(false)

        const onShowMoreClick = block?.actionSlug
          ? () =>
              navigate(`/${block.actionSlug}`, {
                state: { themeId },
              })
          : undefined

        setData({
          title: block?.title,
          blockName: block?.name,
          paddingTop: block.paddingTop,
          paddingBottom: block.paddingBottom,
          background: block.background?.url,
          titleColor: block.titleColor,
          centerTitle: block.centerTitle,
          titleBorderColor: block.titleBorderColor,
          allowTitleLowerCase: block.allowTitleLowerCase,
          titleBorderColorShadowNeon: block.titleBorderColorShadowNeon,
          onShowMoreClick,
          skus,
        })

        setIsLoading(false)
      }

      handleResult()
    }
  }, [queryResult, loading])

  useEffect(() => {
    if (data.skus && data.skus.length > 0 && pageProducts && pageProducts?.length > 0) {
      const productsToShow = pageProducts
        .filter(({ skuCode }) => data.skus.includes(String(skuCode)))
        .filter((product) => product.isDummy !== true && !product.unavailable)

      if (!productsToShow || productsToShow.length === 0) return setRenderList(false)
      setProducts(productsToShow)
      sendViewItemListEvent({
        products: productsToShow.map((product) => ({
          ...product,
          price: toCurrencyNumber(product.price, COUNTRY === 'BO'),
        })),
        currency: getCurrencyCode(COUNTRY),
        list: data?.blockName ?? data?.title,
      })
    }
  }, [data.skus, pageProducts])

  if (!renderList || (pageProducts?.length === 0 && !loadingProducts)) return null

  return (
    <div className={className} data-test="product-list">
      <SliderWrapper
        {...data}
        {...styleProps}
        isLoading={isLoading}
        hideNavigation={!data?.onShowMoreClick}
        title={data.title ?? ''}
        onShowMoreClick={data?.onShowMoreClick}
        paddingTop={data?.paddingTop}
        paddingBottom={data?.paddingBottom}
        bgBottom={useBackground && data?.background ? `url(${data?.background})` : undefined}
      >
        <ProductListSlider
          stockErrorHandler={stockErrorHandler}
          type={type}
          typeCard={type}
          isLoading={loadingProducts || products.length === 0}
          products={products}
          onShowMoreClick={data?.onShowMoreClick}
        />
      </SliderWrapper>
    </div>
  )
}
