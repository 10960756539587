import { OrListFragment, OrNewsFragment } from './fragments'
import { BaseBlock, BlockType, ClientSideOrNews } from './contentfulTypes'

export interface QueryNewsResult {
  orList: {
    blockType: BlockType.NEWS
    itemsCollection: {
      items: ClientSideOrNews[]
    }
  } & BaseBlock
}

export default `
  query NewsQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
      itemsCollection {
        items {
        ...OrNewsFragment
      }
    }
   }
  }
  ${OrListFragment}
  ${OrNewsFragment}
`
