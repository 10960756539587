import React from 'react'
import HeroSimple from './HeroSimple'
import HeroWithCTA from './HeroWithCTA'
import useQuery from '../../../hooks/useQuery'
import heroQuery, { QueryHeroResult } from '../../../graphql/heroQuery'

type Props = {
  listId: string
}

const Hero = ({ listId }: Props) => {
  const { data, loading, error } = useQuery<QueryHeroResult>(heroQuery, { variables: { listId } })
  const orList = data?.orList
  const hero = orList?.itemsCollection.items[0]

  if (error !== undefined || (!hero && !loading)) return null

  if (!hero) return null

  if (hero?.heroType === 'simple') return <HeroSimple image={hero?.image.url} text={hero?.text} />

  return <HeroWithCTA data={hero} />
}

export default Hero
