import React from 'react'
import styled, { css } from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import Video from '../Video'
import SectionTitle from '../Moments/SectionTitle'
import useQuery from '../../hooks/useQuery'
import videoQuery, { QueryVideoResult } from '../../graphql/videoQuery'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  listId: string
  altBackground?: boolean
}

const { cssPrefix, toPrefix } = toCssPrefix('ProductVideo__')
const Wrapper = styled.div<{ altBackground?: boolean; backgroundColor?: string }>`
  padding: 32px 0;
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.background.body};

  .${cssPrefix} {
    &title {
      text-align: center;
      margin: 0;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.bodyText};
    }

    &video {
      max-width: 375px;
      min-width: 375px;
      margin: ${({ altBackground }) => (altBackground ? '0 auto auto' : '24px auto auto')};
      display: block;
      height: 215px;
      ${({ altBackground }) =>
        altBackground &&
        css`
          object-fit: cover;
        `}
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &video {
        max-width: 575px;
        min-width: 575px;
        height: 320px;
        margin: 0;
        border-radius: ${({ theme }) => theme.borderRadius};
      }

      &video-wrapper {
        background: ${({ theme }) => theme.colors.background.body || theme.colors.white};
        padding: 30px;
        margin-top: 40px;
        box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
        border-radius: ${({ theme }) => theme.borderRadius};

        ${({ altBackground }) =>
          altBackground &&
          css`
            max-width: 635px;
            margin: 0 auto;
          `}
      }
    }
  }
`

const ProductVideo = ({ listId, altBackground }: Props) => {
  const { data, loading, error } = useQuery<QueryVideoResult>(videoQuery, { variables: { listId } })

  const orList = data?.orList
  const videoResult = orList?.itemsCollection.items[0]

  if (error !== undefined || (!videoResult && !loading)) return null

  if (!videoResult) return null

  return (
    <Wrapper altBackground={altBackground} {...orList} className={`${cssPrefix}`}>
      {orList?.title && <SectionTitle color={orList?.titleColor} text={orList?.title} {...orList} />}
      <div className={toPrefix('video-wrapper')}>
        <Video
          poster={videoResult?.videoPoster.url}
          className={toPrefix('video')}
          preload="metadata"
          src={videoResult?.video.url}
        />
      </div>
    </Wrapper>
  )
}

export default ProductVideo
