import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import {
  Product,
  breakpoints,
  Anchor,
  ZIndex,
  toCssPrefix,
  ProductCardType,
  CartStockErrorHandler,
} from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'
import Slider from '../FramerSlider'
import ProductCardSkeleton from '../Skeletons/ProductCardSkeleton'

const ProductCard = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ProductCard,
  ssr: false,
})

const { cssPrefix, toPrefix: prefix } = toCssPrefix('SliderComponent__')

const Wrapper = styled.div`
  width: 100%;
`

type SliderProduct = Product & { onNavigate?: () => void }

interface InnerContainerProps {
  desktopGap: number
  mobileGap: number
  cardWidthMobile: number
  cardWidthDesktop: number
  cardHeightMobile: number
  cardHeightDesktop: number
}

const InnerContainer = styled.div<InnerContainerProps>`
  display: flex;
  align-items: center;
  .${cssPrefix} {
    &container {
      &-inner-track {
        gap: 30px;
      }
      &-card {
        &:first-child {
          padding-left: 20px;
        }
      }
      &-show-more {
        z-index: ${ZIndex.low};
        height: ${({ cardHeightMobile }) => cardHeightMobile}px;
        width: ${({ cardWidthMobile }) => cardWidthMobile}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-text {
          text-align: center;
          width: 100px;
          font-weight: bold;
          font-size: 15px;
          margin-top: 10px;
          margin-left: -40px;
          color: ${({ theme }) => theme.colors.bodyText};
        }
        &-button {
          margin-left: -40px;
          padding-top: 4px;
          padding-left: 2px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: ${({ theme }) => theme.colors.red};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &container {
        &-card {
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &container {
        &-card {
          width: 277px;
          &:first-child {
            padding-left: 0;
          }
        }
        &-show-more {
          height: ${({ cardHeightDesktop }) => cardHeightDesktop}px;
          width: ${({ cardWidthDesktop }) => cardWidthDesktop}px;
        }
      }
    }
  }
`

interface ProductListSliderProps extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean
  products: SliderProduct[]
  type?: ProductCardType
  cardWidthMobile?: number
  cardWidthDesktop?: number
  cardHeightMobile?: number
  cardHeightDesktop?: number
  cardsGapMobile?: number
  cardsGapDesktop?: number
  onShowMoreClick?: () => void
  hideNavigation?: boolean
  stockErrorHandler?: CartStockErrorHandler
  useControls?: boolean
  trailingComponent?: React.ReactNode
  typeCard?: ProductCardType
}

export const ProductListSlider = (props: ProductListSliderProps) => {
  const {
    products,
    type = 'responsive',
    cardWidthMobile = 0,
    cardWidthDesktop = 0,
    cardHeightMobile = 0,
    cardHeightDesktop = 0,
    cardsGapMobile = 36,
    cardsGapDesktop = 28,
    onShowMoreClick,
    isLoading = false,
    stockErrorHandler,
    useControls,
    trailingComponent = null,
    typeCard,
    ...restProps
  } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainer
        mobileGap={cardsGapMobile}
        desktopGap={cardsGapDesktop}
        cardWidthMobile={cardWidthMobile}
        cardWidthDesktop={cardWidthDesktop}
        cardHeightMobile={cardHeightMobile}
        cardHeightDesktop={cardHeightDesktop}
      >
        <Slider
          useControls={useControls || type !== 'small'}
          itemsCount={products.length}
          className={prefix('container')}
        >
          {isLoading ? (
            <>
              {Array(5)
                .fill(0)
                .map((_, i) => (
                  <ProductCardSkeleton key={i} />
                ))}
            </>
          ) : (
            <>
              {products.map((product) => {
                if (!product.unavailable) {
                  return (
                    <div className={prefix('container-card')} key={product.skuCode}>
                      <ProductCard
                        onNavigate={product.onNavigate}
                        product={product}
                        type={typeCard}
                        stockErrorHandler={stockErrorHandler}
                      />
                    </div>
                  )
                }
                return null
              })}
            </>
          )}
          {onShowMoreClick && !trailingComponent ? (
            <div className={prefix('container-show-more')}>
              <Anchor className={prefix('container-show-more-button')} onClick={() => onShowMoreClick()}>
                <Icon iconId="chevron_right" size="22" />
              </Anchor>
              <span className={prefix('container-show-more-text')}>Ver toda la categoría</span>
            </div>
          ) : (
            trailingComponent
          )}
        </Slider>
      </InnerContainer>
    </Wrapper>
  )
}
