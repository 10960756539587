const Products3 = Array(15)
  .fill({
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/6lMBXj4odQIOb4OdN8OsAH/f835e143f0043713a1d73746b31af032/7801610000571.jpg',
    title: 'Coca-Cola Zero 1.5 Litros.',
    price: 240,
  })
  .map((item, index) => ({ ...item, skuCode: `${index}-${item.title}` }))

export default Products3
