import { ClientSideBlockQueryResult } from './contentfulTypes'

export interface QueryResultItem {
  slug: string
  name: string
  image: {
    url: string
  }
}

export type QueryResult = ClientSideBlockQueryResult<QueryResultItem>

export default `
  query CategoriesQuery($listId: String!) {
    orList(id: $listId) {
      title
      paddingTop
      paddingBottom
      itemsCollection {
        items {
          ... on Category {
            name
            slug
            image {
              url
            }
          }
        }
      }
    }
  }
`
