import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import MomentCard, { MomentCardType } from './MomentCard'
import Slider from '../FramerSlider'

const { cssPrefix, toPrefix } = toCssPrefix('MomentsList__')

type Props = {
  items: MomentCardType[]
  isFixed?: boolean
}

const Wrapper = styled.div`
  .${cssPrefix} {
    &fixed {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 15px 0;
    }
    &slider {
      padding: 15px 0;
    }

    &card {
      margin: 0 10px;

      &:first-child {
        margin-left: 24px;
      }

      &:last-child {
        margin-right: 24px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 0 90px;
  }
`

const MomentsCardsList = ({ items, isFixed }: Props) => {
  if (items.length === 0) return null

  return (
    <Wrapper className={cssPrefix}>
      {isFixed ? (
        <div className={toPrefix('fixed')}>
          {items.map((item) => (
            <MomentCard key={item.id} className={toPrefix('card')} {...item} />
          ))}
        </div>
      ) : (
        <Slider className={toPrefix('slider')} useControls={false} itemsCount={items.length}>
          {items.map((item) => (
            <MomentCard key={item.id} className={toPrefix('card')} {...item} />
          ))}
        </Slider>
      )}
    </Wrapper>
  )
}

export default MomentsCardsList
