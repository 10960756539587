import { ClientSideBlock } from './contentfulTypes'

export interface HomeBlocksQueryResult {
  pgPageCollection: {
    items: {
      blocksCollection: {
        items: ClientSideBlock[]
      }
      template: {
        blocksCollection: {
          items: ClientSideBlock[]
        }
      }
    }[]
  }
}

export default `
  query BlocksQuery {
  pgPageCollection(where: {pageType: "home"}, limit: 1) {
    items {
      blocksCollection(limit: 20) {
        items {
          name
          blockType
          background {
            url
          }
          sys {
            id
          }
          itemsCollection(limit: 30) {
            items {
              ... on Product {
                sku
              }
              ... on Bundle {
                sku
              }
              ... on OrFilteredList {
                attributeToFilter
                optionalFilter
                filtersCollection {
                  items {
                    label
                  }
                }  
              }
            }
          }
        }
      }
    }
  }
}
`
