const Products2 = Array(5)
  .fill({
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/2lvuO8se7PVpwNiWAWEWnh/13a9e072ddec089a6a7926a0cef0b6b8/7801610323236.jpg',
    title: 'Coca-Cola Zero 1.5 Litros.',
    price: 4990,
    unavailable: false,
    category: 'Bebida a base de soya',
    netContent: 335,
    unitName: 'ml',
    packing: 'Botella no retonable',
  })
  .map((item, index) => ({ ...item, skuCode: `${index}-${item.title}` }))

export default Products2
