import { navigate } from 'gatsby'
import { Product } from '@ecommerce/shared'
import secrets from '../config/secrets'
import { sendSelectItemEvent } from './events'
import { generateProductUrl } from '../config/siteBuild/utils'

const { COUNTRY } = secrets

type ProductNavigateParams = {
  product: Product
  listName: string
  navigationState?: Record<string, unknown>
}

export const tagManagerCheckoutActionFields = {
  init: { actionField: { step: 1, option: 'Inicio checkout', event: 'begin_checkout' } },
  shippingAddress: { actionField: { step: 2, option: 'Dirección', event: 'add_shipping_info' } },
  shippingDate: (date: string) => ({ actionField: { step: 3, option: date, event: 'add_date_info' } }),
  paymentMethod: (paymentMethod: string) => ({
    actionField: { step: 4, option: paymentMethod, event: 'add_payment_info' },
  }),
  confirmCheckout: { actionField: { step: 5, option: 'Confirmado', event: 'confirm_checkout' } },
}

export const productNavigate = ({ product, listName, navigationState = {} }: ProductNavigateParams) => {
  sendSelectItemEvent(product, listName || '', COUNTRY === 'BO')

  return navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
    state: { originListName: listName, ...(navigationState || {}) },
  })
}
