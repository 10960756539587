import React, { useRef } from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { RichTextJson } from '../../graphql/contentfulTypes'
import slugFallback from '../../utils/slugFallback'

const { cssPrefix, toPrefix } = toCssPrefix('RibbonMessage__')

interface MessageWrapperProps {
  clickable?: boolean
}

const Wrapper = styled.div<MessageWrapperProps>`
  .${cssPrefix} {
    &container {
      user-select: none;
      width: calc(100vw - 32px);
      min-width: calc(100vw - 32px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: 52px;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0 auto;

      a {
        cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
        color: red;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &container {
        height: 32px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &container {
        height: 32px;
      }
    }
  }
`

type MessageProps = { message: RichTextJson; url: string; index: number; marketSlug?: string }

export const Message = (props: MessageProps) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <Wrapper ref={ref} clickable={!!props.url}>
      <div className={toPrefix('container')}>
        {documentToReactComponents(props.message.json, {
          renderNode: {
            [INLINES.HYPERLINK]: (node, child) => {
              return (
                <a
                  target={props.url?.startsWith('/') ? '_self' : '_blank'}
                  rel="noopener noreferrer"
                  href={slugFallback(props.url)}
                >
                  {child}
                </a>
              )
            },
          },
        })}
      </div>
    </Wrapper>
  )
}
