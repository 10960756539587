import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import { getStoredDistributionCenter, useLocation, useShoppingCart, useAuth, getStoredMarket } from '@ecommerce/shared'
import { useTabs } from '../../hooks/useTabs'
import Layout from '../../components/Layout'
import { PgPageProps } from '../../types/PgPages'
import withPageTransition from '../../components/withPageTransition'
import HomeTemplate from './HomeTemplate'
import { contentfulThemeToThemeExtension } from '../../graphql/global/theme'
import { HomeQueryResult } from './homeQuery'
import { sendPageViewEvent } from '../../utils/events'

type Props = PageProps<{}, { data: HomeQueryResult } & PgPageProps['pageContext']>

const Index = (props: Props) => {
  const { isBolivia } = useLocation()
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'home', title: 'Home' })
  }, [])

  const { productCategories, data, currentDistributionCenter, currentMarket } = props.pageContext
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter
  const storedMarket = getStoredMarket() || currentMarket

  const getPage = () => {
    if (data.locationPage && data.locationPage.edges.length > 0) return data.locationPage.edges[0].node
    if (data.subLocationPage && data.subLocationPage.edges.length > 0) return data.subLocationPage.edges[0].node
    return data.allContentfulPgPage.edges[0].node
  }

  const {
    description,
    keywords,
    title: titlePage,
    blocks: pageBlocks,
    template: { blocks: templateBlocks },
    theme: pageTheme,
  } = getPage()

  const blocks = pageBlocks && pageBlocks.length ? pageBlocks : templateBlocks

  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: any) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })

  const { tabs } = useTabs()

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="canonical" href={`${isBolivia() ? 'https://micoca-cola.bo/' : 'https://embonor.micoca-cola.cl/'}`} />
      </Helmet>
      <Layout
        description={description}
        keywords={keywords}
        id="layout"
        title={titlePage}
        pathName="/"
        location={props.location}
        categories={productCategories}
      >
        <HomeTemplate
          currentDistributionCenter={storedDistributionCenter}
          currentMarket={storedMarket}
          pageBlocks={blocks}
          tabs={tabs}
        />
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(Index)
