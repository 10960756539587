import { ClientSideBlockQueryResult } from './contentfulTypes'

export type QueryResult = ClientSideBlockQueryResult<{ sku: string }>

export default `
  query ProductListQuery($listId: String!) {
    orList(id: $listId) {
      name
      title
      actionSlug
      paddingTop
      paddingBottom
      titleColor
      centerTitle
      titleBorderColor
      allowTitleLowerCase
      titleBorderColorShadowNeon
      background {
        url
      }
      itemsCollection {
        items {
          ... on Product {
            sku
          }
          ... on Bundle {
            sku
          }
        }
      }
    }
  }
`
