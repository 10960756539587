const Products1 = Array(15)
  .fill({
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/6T3vB1QtMPrvvjoqOSxTj/509732f14df58d6e41ea27e6ff8c1b06/7801610022115.jpg',
    title: 'Coca-Cola Original.',
    price: 3990,
    recyclable: false,
  })
  .map((item, index) => ({ ...item, skuCode: `${index}-${item.title}` }))

export default Products1
