import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoints, useLocation } from '@ecommerce/shared'
import ribbonMessagesQuery, { QueryRibbonMessages, RibbonMessagesQueryResult } from '../../graphql/ribbonMessagesQuery'
import useQuery from '../../hooks/useQuery'
import { RibbonMessages } from './RibbonMessages'

const WidgetWrapper = styled.div`
  height: 52px;
  @media (${breakpoints.tabletPortrait.min}) {
    height: 32px;
  }
`

interface WidgetStateData {
  title?: string
  type: 'ribbon-messages'
  items: QueryRibbonMessages[]
}

interface RibbonMessageSliderWidgetProps {
  listId: string
  marketSlug?: string
}

export const RibbonMessageSliderWidget = ({ listId, marketSlug }: RibbonMessageSliderWidgetProps) => {
  const [messagesData, setMessagesData] = useState<WidgetStateData>({
    type: 'ribbon-messages',
    items: [],
  })
  const [isLoading, setLoading] = useState(true)
  const [renderLists, setRenderLists] = useState(true)

  const { data, loading, error } = useQuery<RibbonMessagesQueryResult>(ribbonMessagesQuery, { variables: { listId } })

  useEffect(() => {
    if (data && !loading) {
      const block = data?.orList

      if (!block) return setRenderLists(false)

      setMessagesData({
        type: block?.blockType ?? 'ribbon-messages',
        items: block?.itemsCollection?.items ?? [],
        title: block?.title,
      })
      setLoading(false)
    }
  }, [data, loading])

  const {
    state: { country },
  } = useLocation()

  if (!renderLists || error !== undefined || (messagesData.items.length === 0 && !isLoading)) return null
  return (
    <WidgetWrapper>
      <RibbonMessages marketSlug={marketSlug} messages={messagesData.items} />
    </WidgetWrapper>
  )
}
