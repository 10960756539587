import { OrListFragment, MlVideoFragment } from './fragments'
import { BaseBlock, BlockType, ClientSideMlVideo } from './contentfulTypes'

export interface QueryVideoResult {
  orList: {
    blockType: BlockType.SINGLE_VIDEO
    itemsCollection: {
      items: ClientSideMlVideo[]
    }
  } & BaseBlock
}

export default `
  query VideoQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
      itemsCollection {
        items {
        ...MlVideoFragment
      }
    }
   }
  }
  ${OrListFragment}
  ${MlVideoFragment}
`
