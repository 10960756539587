const ProductsTest = [
  {
    skuCode: 'Limón',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/3JDKGM2haHFL4arKZX2Gr8/928ec211dd77de0ca7cdee04eb4e8ce3/2203908005671.jpg?h=250',
    title: 'Limón',
    price: 2500,
    unavailable: false,
    category: 'Bebida jugosa',
    netContent: 1500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Coca-Cola Sin Azúcar 350 ml.-1',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/2yCjQtpC056qBTrZIpnKRA/ddef4c75a2b8b5e916d5df1e9507ff79/7801610350355.jpg',
    title: 'Coca-Cola Sin Azúcar 350 ml.',
    price: 500,
    unavailable: false,
    category: 'Bebida gaseosa',
    netContent: 350,
    unitName: 'ml',
    packing: 'Botella no retonable',
    isBundle: true,
  },
  {
    skuCode: 'Coca-Cola Sin Azúcar 2.5 Litros.-2',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/58Zn4ADbVWyeC5fFflYuhP/674600158b90e5d916956813a3ba683e/7801610350850.jpg',
    title: 'Coca-Cola Sin Azúcar 2.5 Litros.',
    price: 600,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 2500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Coca-Cola Light 3 Litros.-3',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/14XsKlk3vwwC0asIU0wgHC/1193fbcb1590746d000de5161b402c8b/7801610352151.jpg',
    title: 'Coca-Cola Light 3 Litros.',
    price: 1000,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 2000,
    unitName: 'ml',
    packing: 'Botella no retonable',
    isBundle: true,
  },
  {
    skuCode: 'Coca-Cola Sin Azúcar 2.5 Litros.-4',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/33GhSRAbXvdpPYSGmVfjNw/21ba0aa6a4987744d205ab23ca414537/7801610350652.jpg',
    title: 'Coca-Cola Sin Azúcar 2.5 Litros.',
    price: 1000,
    unavailable: false,
    category: 'Bebida Gaseosa de refresco',
    netContent: 2500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Coca-Cola Zero Sin Azúcar 1.5 Litros.-5',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/51ZHs7SUKythrbztq4DdKM/febd9ccab9892f43e4dd645defd7e2bd/7801610350409.jpg',
    title: 'Coca-Cola Zero Sin Azúcar 1.5 Litros.',
    price: 500,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 1500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Limon 500 ml.-6',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/3JDKGM2haHFL4arKZX2Gr8/928ec211dd77de0ca7cdee04eb4e8ce3/2203908005671.jpg',
    title: 'Limon 500 ml.',
    price: 600,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Frambuesa 500 ml.-7',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/4LRRuuFZPhbccCYnLV1ak9/4eac42b671786d14954407468194bcb5/2203908005700.jpg',
    title: 'Frambuesa 500 ml.',
    price: 700,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Lima Limon 3L',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/3kvR7D8etgVSu3eKzZjk7m/890b1a1dd5b89fc987bb4cb47ab53804/7801610333525.jpg',
    title: 'Lima Limon 3l.',
    price: 700,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
  {
    skuCode: 'Naranja 3L',
    image:
      'https://images.ctfassets.net/16npdkkoi5mj/3r8mUmLmOFCQV3igIhhnMN/370edc1010d869eb59ce34539733f12a/7801610333426.jpg',
    title: 'Naranja 3l.',
    price: 700,
    unavailable: false,
    category: 'Bebida Gaseosa',
    netContent: 500,
    unitName: 'ml',
    packing: 'Botella no retonable',
  },
]

export default ProductsTest
