import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { toCssPrefix, Image, Button, breakpoints, Market } from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ClientSideOrNews } from '../../graphql/contentfulTypes'
import slugFallback from '../../utils/slugFallback'

type Props = { data: ClientSideOrNews }

type WrapperProps = {
  hasTitle: boolean
  hasImageBottom: boolean
  descriptionColorBold?: string
}

const { cssPrefix, toPrefix, toPrefixArray } = toCssPrefix('NewsItemSlide__')

const Wrapper = styled.div<WrapperProps>`
  width: 300px;
  align-self: stretch;
  padding: ${({ hasImageBottom }) => (hasImageBottom ? '20px 20px 180px' : '20px 20px 80px')};
  background: ${({ theme }) => theme.colors.white};
  margin-right: 22px;
  border-radius: 15px;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  text-align: center;
  position: relative;

  .${cssPrefix} {
    &image {
      width: 150px;
      height: 150px;
      object-fit: cover;
      display: block;
      margin: -55px auto auto;
      border-radius: 50%;
      user-select: none;
      user-drag: none;
    }

    &title {
      margin: 25px 0 16px;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
    }

    &description {
      color: ${({ theme }) => theme.colors.bodyText};
      font-size: ${({ hasTitle }) => (hasTitle ? '17px' : '18px')};
      line-height: ${({ hasTitle }) => (hasTitle ? '20px' : '22px')};
      width: ${({ hasTitle }) => (hasTitle ? '100%' : '80%')};
      margin: 0 auto;
    }

    &rich-text {
      p {
        margin: ${({ hasTitle }) => (hasTitle ? '24px 0' : '24px 0 0 0')};

        b {
          color: ${({ descriptionColorBold }) => descriptionColorBold};
        }
      }
    }

    &image-bottom {
      width: 210px;
      height: 111px;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
      position: absolute;
      bottom: 53px;
      left: calc(50% - 105px);
    }

    &button {
      width: 160px;
      height: 47px;
      position: absolute;
      bottom: 20px;
      left: calc(50% - 80px);
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    width: 320px;

    .${cssPrefix} {
      &image-bottom {
        width: 213px;
        height: 112px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 415px;
    margin-right: 25px;
    ${({ hasTitle }) => !hasTitle && 'padding: 20px 20px 171px;'}

    .${cssPrefix} {
      &button {
        width: 210px;
        left: calc(50% - 105px);
      }

      ${({ hasTitle }) =>
        !hasTitle &&
        `
        &image {
          width: 132px;
          height: 132px;
        }

        &description {
          font-size: 24px;
          line-height: 31px;
          width: 85%;
        }
      `}

      &image-bottom {
        width: 278px;
        height: 147px;
        bottom: 22px;
        left: calc(50% - 139px);
      }
    }
  }
`

const News = ({
  data: {
    sliderTitle,
    sliderImage,
    slug,
    sliderDescription,
    sliderRichTextDescription,
    sliderImageBottom,
    sliderDescriptionColorBold,
    sliderButtonColor,
  },
}: Props) => {
  return (
    <Wrapper
      className={cssPrefix}
      hasTitle={!!sliderTitle}
      hasImageBottom={!!sliderImageBottom}
      descriptionColorBold={sliderDescriptionColorBold}
    >
      <Image className={toPrefix('image')} src={sliderImage.url} alt={sliderTitle} params={{ q: 80 }} />
      {sliderTitle && <h3 className={toPrefix('title')}>{sliderTitle}</h3>}
      {sliderRichTextDescription ? (
        <div className={toPrefixArray(['description', 'rich-text'])}>
          {documentToReactComponents(sliderRichTextDescription.json)}
        </div>
      ) : (
        <p className={toPrefix('description')}>{sliderDescription}</p>
      )}
      {sliderImageBottom ? (
        <Image
          className={toPrefix('image-bottom')}
          src={sliderImageBottom.url}
          alt="slider image bottom"
          params={{ q: 80 }}
        />
      ) : (
        <Button
          onClick={() => navigate(`${slug.startsWith('/') ? `/noticias${slug}` : `${slugFallback(slug)}`}`)}
          btnColor={sliderButtonColor}
          className={toPrefix('button')}
        >
          Ver más...
        </Button>
      )}
    </Wrapper>
  )
}

export default News
