import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import SectionTitle from '../Moments/SectionTitle'
import Slider from '../FramerSlider'
import News from './News'
import useQuery from '../../hooks/useQuery'
import newsQuery, { QueryNewsResult } from '../../graphql/newsQuery'

type Props = {
  listId: string
}

const Wrapper = styled.div<{ itemsCount: number }>`
  padding-top: 24px;
  padding-bottom: 24px;

  .NewsSlider {
    padding: 40px 0 12px;
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding-bottom: 32px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding-bottom: 32px;
  }

  @media screen and (${breakpoints.desktopHD.min}) {
    ${({ itemsCount }) =>
      itemsCount <= 3
        ? css`
            .NewsSlider-inner {
              justify-content: center;
            }
          `
        : ''}
  }
`

const NewsList = ({ listId }: Props) => {
  const { data, loading, error } = useQuery<QueryNewsResult>(newsQuery, { variables: { listId } })

  const orList = data?.orList
  const news = orList?.itemsCollection.items

  if (!news || error !== undefined || (!news?.length && !loading)) return null

  return (
    <Wrapper itemsCount={news.length}>
      {orList?.title && <SectionTitle color={orList?.titleColor} text={orList?.title} {...orList} />}
      <Slider className="NewsSlider" itemsCount={news?.length}>
        {news?.map((item) => (
          <News key={item.sys.id} data={item} />
        ))}
      </Slider>
    </Wrapper>
  )
}

export default NewsList
