import { useLocation } from '@ecommerce/shared'
import { getTabs } from '../mocks'
import { Tab } from '../components/TabMenu'

export const useTabs = (): { tabs: Tab[] } => {
  const {
    isBolivia,
    state: { currentCity },
  } = useLocation()
  const tabs = getTabs(currentCity?.slug, isBolivia())
  return { tabs }
}
