import React from 'react'
import { navigate } from 'gatsby'
import Categories, { Item } from '../../components/CategoriesList'
import homeCategoriesQuery, { QueryResult } from '../../graphql/homeCategoriesQuery'
import useQuery from '../../hooks/useQuery'
import { SimpleBlockWrapper } from '../../utils/blocks'

type Props = {
  listId: string
}

const HomeCategories = ({ listId }: Props) => {
  const { loading, error, data } = useQuery<QueryResult>(homeCategoriesQuery, { variables: { listId } })

  if (!loading && !data) return null

  const queryCategories =
    data?.orList?.itemsCollection?.items?.map<Item>((category) => ({
      label: category?.name || '',
      imageUrl: category?.image?.url || '',
      onOpen: () => navigate(`category/${category.slug}`),
    })) ?? []

  return (
    <SimpleBlockWrapper {...(data?.orList ?? {})}>
      <Categories items={queryCategories} isLoading={loading || error !== undefined} />
    </SimpleBlockWrapper>
  )
}

export const CategoriesSkeleton = () => {
  return <Categories items={[]} isLoading />
}

export default HomeCategories
