import React, { useState, useEffect } from 'react'
import { breakpoints, useResolution } from '@ecommerce/shared'
import styled from 'styled-components'
import useQuery from '../../hooks/useQuery'
import MomentsCardsList from '../../components/Moments/MomentsCardsList'
import SectionTitle from '../../components/Moments/SectionTitle'
import { MlMomentCardFragment, OrListFragment } from '../../graphql/fragments'
import { BaseBlock } from '../../graphql/contentfulTypes'

const query = `
  query MomentsListQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ... MlMomentCardFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${MlMomentCardFragment}
`

type MomentsListQueryResult = {
  orList: {
    itemsCollection: {
      items: {
        image: {
          url: string
          sys: {
            id: string
          }
        }
        name: string
        slug: string
        subtitle: string
        title: string
        sys: {
          id: string
        }
      }[]
    }
  } & BaseBlock
}

export interface MomentsCardsListProps {
  listId: string
}

function MomentsCardsListWidget({ listId }: MomentsCardsListProps) {
  const [momentsData, setMomentsData] = useState<MomentsListQueryResult['orList'] | null>(null)

  const { data, loading } = useQuery<MomentsListQueryResult>(query, { variables: { listId } })
  const { isMobile } = useResolution()

  useEffect(() => {
    if (data && !loading) setMomentsData(data.orList)
  }, [data, loading])

  return momentsData && !loading ? (
    <div>
      {momentsData?.title && (
        <SectionTitle {...momentsData} color={momentsData?.titleColor} text={momentsData?.title} {...data?.orList} />
      )}
      <MomentsCardsList
        isFixed={!isMobile && momentsData.itemsCollection.items.length < 3}
        items={momentsData.itemsCollection.items.filter(Boolean).map((item) => ({
          ...item,
          slug: item.slug.startsWith('/') ? `${item.slug}` : `momento/${item.slug}`,
          id: item.sys.id,
          image: {
            file: { url: item.image.url },
            id: item.image.sys.id,
          },
        }))}
      />
    </div>
  ) : null
}

export default MomentsCardsListWidget
