import React from 'react'
import { Icon } from '../components/Icon/Icon'
import { Tab } from '../components/TabMenu'

function getTabs(slugLocation?: string, isBolivia?: boolean): Tab[] {
  return [
    { slug: `/${slugLocation}`, label: 'Inicio', icon: <Icon iconId="home" />, dataTest: 'home-tab-button' },
    {
      slug: `/${slugLocation}/category`,
      label: 'Categorías',
      icon: <Icon iconId="grid" />,
      dataTest: 'categories-tab-button',
    },
    {
      slug: `/${slugLocation}/promotion`,
      label: isBolivia ? 'Ofertas' : 'Promociones',
      icon: <Icon iconId="Promociones" />,
      dataTest: 'promotions-tab-button',
    },
  ].map((tab) => {
    return { ...tab, slug: slugLocation ? tab.slug : '/' }
  })
}

export default getTabs
